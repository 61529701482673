const store = {
	meta: {
		channel: undefined,
		accessToken: undefined,
	},
	line: {
		isLoggedIn: false,
		token: undefined,
		userProfile: null,
	},
	company: {
		id: null,
		slug: null,
		liffId: null,
		data: null,
	},
	landing: {
		data: null,
		banner: null,
	},
	campaign: {
		data: null,
		mission: null,
		missionAnswers: {},
	},
}

export default store
