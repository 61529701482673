import dayjs from 'dayjs'
require('dayjs/locale/th')

const globalPlugin = {
	install(Vue) {
		Vue.prototype.$date = (date, format = 'DD MMMM YYYY HH:mm') => {
			if (!date) {
				return ''
			}
			return dayjs(date).locale('th').format(format)
		}
	},
}

export default globalPlugin
