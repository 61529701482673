import Vue from 'vue'
import App from './App.vue'
import router from './router'
import globalPlugin from './plugins/globalPlugin'
import filters from './plugins/filters'
import carousel from './plugins/carousel'
import './plugins/VeeValidate'

import 'sweetalert2/src/sweetalert2.scss'
import './assets/tailwind.css'
import './assets/style/main.scss'

Vue.config.productionTip = false

Vue.use(globalPlugin)
Vue.use(filters)
Vue.use(carousel)
Vue.mixin({
	data() {
		return {
			isLoading: false,
		}
	},
	methods: {
		setLoading(value) {
			this.isLoading = value
		},
		getConfig(type, templateConfig) {
			switch (type) {
				case 'stamp-card':
					return templateConfig.stampCard
				case 'send-picture':
					return templateConfig.sendPicture
				case 'vote':
					return templateConfig.vote
				case 'watch-video':
					return templateConfig.watchVideo
				case 'questionnaire':
					return templateConfig.questionnaire
				case 'photo-hunt':
					return templateConfig.photoHunt
				case 'point-collection-code':
					return templateConfig.pointCollectionCode
				case 'friends-get-friends':
					return templateConfig.friendsGetFriends
				case 'click-and-get-something':
					return templateConfig.clickAndGetSomething
				case 'lucky-draw':
					return templateConfig.luckyDraw
				case 'survey-template':
					return templateConfig.surveyTemplate
				case 'web-template':
					return templateConfig.webTemplate
				case 'dynamic':
					return templateConfig.dynamic
				default:
					return null
			}
		},
		getCampaignObjectKeyByType(type) {
			switch (type) {
				case 'stamp-card':
					return 'stampCard'
				case 'send-picture':
					return 'sendPicture'
				case 'vote':
					return 'vote'
				case 'watch-video':
					return 'watchVideo'
				case 'questionnaire':
					return 'questionnaire'
				case 'photo-hunt':
					return 'photoHunt'
				case 'point-collection-code':
					return 'pointCollectionCode'
				case 'friends-get-friends':
					return 'friendsGetFriends'
				case 'click-and-get-something':
					return 'clickAndGetSomething'
				case 'lucky-draw':
					return 'luckyDraw'
				case 'survey-template':
					return 'surveyTemplate'
				case 'web-template':
					return 'webTemplate'
				case 'dynamic':
					return 'dynamic'
				default:
					return null
			}
		},
	},
})

new Vue({
	router,
	render: (h) => h(App),
}).$mount('#app')
