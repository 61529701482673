import dayjs from 'dayjs'

const filters = {
	install(Vue) {
		Vue.filter('stripHTML', function (value) {
			const div = document.createElement('div')
			div.innerHTML = value
			const text = div.textContent || div.innerText || ''
			return text
		})
		Vue.filter('date', function (value) {
			if (!value) {
				return '-'
			}
			return dayjs(value).format('DD/MM/YYYY')
		})
		Vue.filter('dateTime', function (value) {
			if (!value) {
				return '-'
			}
			return dayjs(value).format('DD/MM/YYYY HH:mm:ss')
		})
		Vue.filter('jsDateToStr', function (value) {
			if (value == null) {
				return '-'
			}
			return dayjs(value).format('YYYY-MM-DD')
		})
		Vue.filter('descGroupFilter', function (value) {
			switch (value) {
				case 'hour':
					return 'max 7 days'
				case 'day':
					return 'max 90 days'
				case 'month':
					return 'max 24 months'
				case 'year':
					return 'max 3 years'
				default:
					return ''
			}
		})
		Vue.filter('number', function (value) {
			if (value == null) {
				return '-'
			}
			return (+parseFloat(value).toFixed(2)).toLocaleString()
		})
		Vue.filter('percent', function (value) {
			if (value === null) {
				return '-'
			}
			if (value === '+Inf' || value === '-Inf') {
				return value
			}
			return (Math.floor(+value * 100) / 100).toLocaleString()
		})
	},
}

export default filters
