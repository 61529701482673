import Vue from 'vue'
import VueFlicking from '@egjs/vue-flicking'
import { AutoPlay, Pagination } from '@egjs/flicking-plugins'

// import '@egjs/vue-flicking/dist/flicking.css'

// import '@egjs/flicking-plugins/dist/flicking-plugins.css'
// import '@egjs/flicking-plugins/dist/pagination.css'

const carouselPlugins = [
	new AutoPlay({ duration: 10000, direction: 'NEXT', stopOnHover: true }),
	new Pagination({ type: 'bullet' }),
]

Vue.use(VueFlicking)

const plugin = {
	install(Vue) {
		Vue.prototype.carouselPlugins = carouselPlugins
	},
}

export default plugin
