<script>
import DefaultLayout from '@/layout/DefaultLayout.vue'
import LoadingLayout from '@/layout/LoadingLayout.vue'
import api from '@/api'
import liff from '@line/liff'
import store from '@/store/index'
import webfont from 'webfontloader'

export default {
	data() {
		return {
			store,
			companySlug: null,
		}
	},
	computed: {
		layout() {
			if (this.isLoading) {
				return LoadingLayout
			}
			return this.store.line.isLoggedIn ? DefaultLayout : LoadingLayout
		},
		fontFamily() {
			if (!this.store.company.data) {
				return ''
			}
			return `${this.store.company.data.theme.font}, sans-serif`
		},
		fontColor() {
			if (!this.store.company.data) {
				return ''
			}
			return this.store.company.data.theme.color
		},
	},
	watch: {
		'$route.query': {
			async handler(to) {
				const slug = to.company
				if (!slug) {
					return
				}
				this.companySlug = slug
				this.store.company.slug = slug
				this.store.meta.channel = to.channel || this.store.meta.channel
				this.store.meta.accessToken = to.accessToken || this.store.meta.accessToken
				await this.getCompany()
			},
		},
	},
	methods: {
		async getCompany() {
			const res = await api.company.get(this.companySlug)

			this.store.company.id = res.data.id
			this.store.company.slug = this.companySlug
			this.store.company.liffId = res.data.liffId
			this.store.company.data = res.data

			const oldPath = this.$route.fullPath
			const newPath = this.$route.fullPath.replaceAll('//', '/')

			if (newPath !== oldPath) {
				this.$router.push(newPath)
				return
			}

			if (!this.store.line.isLoggedIn) {
				try {
					await liff.init({
						liffId: res.data.liffId,
					})
				} catch (err) {
					console.log(err)
				}

				if (this.store.meta.accessToken) {
					this.store.line.isLoggedIn = true
				} else if (liff.isLoggedIn()) {
					this.store.line.token = liff.getAccessToken()
					liff.getProfile().then((data) => {
						this.store.line.userProfile = data
					})
					this.store.line.isLoggedIn = true
				} else {
					liff.login()
				}
			}
			webfont.load({
				google: {
					families: [res.data.theme.font],
				},
			})
		},
	},
}
</script>

<template>
	<div :style="{ color: fontColor, fontFamily: fontFamily }">
		<component :is="layout"></component>
	</div>
</template>
