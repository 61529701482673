import axios from 'axios'
import Swal from 'sweetalert2'
import store from '@/store'

// axios.defaults.withCredentials = true

const getCommonHeaders = () =>
	JSON.parse(
		JSON.stringify({
			'X-Channel': store.meta.channel || undefined,
			'X-Access-Token': store.meta.accessToken || undefined,
			'X-Company-Id': store.company.id || undefined,
			'X-Line-Token': store.line.token || undefined,
		}),
	)

axios.interceptors.response.use(
	function (response) {
		return response
	},
	async function (error) {
		if (error.response.status === 400) {
			if (error.response.data.error.message === 'เกมส์ของคุณได้จบลงแล้ว') {
				await Swal.fire({
					text: error.response.data.error.message || 'ระบบผิดพลาด',
				})
			} else {
				return Promise.reject(error.response)
			}
		}
		if (error.response.status === 404) {
			return Promise.reject(error.response)
		}
		return Promise.reject(error)
	},
)

const baseDomain = process.env.VUE_APP_API // base domain for uat
// const baseDomain = '/app'
const baseImageDomain = process.env.VUE_APP_DOMAIN_IMAGE
axios.defaults.baseURL = baseDomain

const pointHistory = (paginate) => {
	return axios.post(
		'/points/history',
		{
			paginate,
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const companyGet = (id) => {
	return axios.get('/companies/' + id)
}

const companyGetLanding = (id) => {
	return axios.get('/companies/landings/' + id, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const companyGetCampaign = (id) => {
	return axios.get('/companies/campaigns/' + id, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignAdditionalUplaod = async (id, file) => {
	const formData = new FormData()
	formData.append('file', file)

	const res = await axios.post('/campaigns/' + id + '/result/additional/upload', formData, {
		headers: {
			...getCommonHeaders(),
			'Content-Type': 'multipart/form-data',
		},
	})
	return baseImageDomain + res.data.filename
}

const campaignAdditionalSubmit = (id, data) => {
	return axios.post('/campaigns/' + id + '/result/additional', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignAdditionalCheck = (id) => {
	return axios.post(
		'/campaigns/' + id + '/check/additional',
		{},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignStampCardSubmitAnswer = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/stamp-card', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignStampCardSubmitPhoto = async (id, file) => {
	const formData = new FormData()
	formData.append('file', file)

	const res = await axios.post('/campaigns/' + id + '/result/stamp-card/upload-photo', formData, {
		headers: {
			...getCommonHeaders(),
			'Content-Type': 'multipart/form-data',
		},
	})
	return baseImageDomain + res.data.filename
}

const campaignQuestionnaireSubmitAnswer = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/questionnaire', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignQuestionnaireSubmitAnswerSubCampaign = (campaignId, data) => {
	const endpoint = `/campaigns/${campaignId}/result/dynamic`

	return axios.put(
		endpoint,
		{
			userResultId: data.userResultId,
			campaignDynamicId: data.campaignDynamicId,
			result: {
				questionnaire: {
					...data,
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignQuestionnaireSubmitPhoto = async (id, file) => {
	const formData = new FormData()
	formData.append('file', file)

	const res = await axios.post(
		'/campaigns/' + id + '/result/questionnaire/upload-photo',
		formData,
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'multipart/form-data',
			},
		},
	)
	return baseImageDomain + res.data.filename
}

const campaignQuestionnaireSubmitPhotoSubCampaign = async (campaignId, campaignDynamicId, file) => {
	const endpoint = `/campaigns/${campaignId}/result/dynamic/${campaignDynamicId}/questionnaire/upload-photo`

	const formData = new FormData()
	formData.append('file', file)

	const res = await axios.post(endpoint, formData, {
		headers: {
			...getCommonHeaders(),
			'Content-Type': 'multipart/form-data',
		},
	})
	return baseImageDomain + res.data.filename
}

const campaignFriendsGetFriendsSubmitAnswer = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/friends-get-friends', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignFriendsGetFriendsSubmitPhoto = async (id, file) => {
	const formData = new FormData()
	formData.append('file', file)

	const res = await axios.post(
		'/campaigns/' + id + '/result/friends-get-friends/upload',
		formData,
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'multipart/form-data',
			},
		},
	)
	return baseImageDomain + res.data.filename
}

const campaignInitiatePicture = async (campaignId) => {
	return axios.put(
		'/campaigns/' + campaignId + '/result/send-picture',
		{
			userResultId: 0,
		},
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'application/json',
			},
		},
	)
}

const campaignInitiatePictureSubCampaign = async (campaignId, campaignDynamicId, userResultId) => {
	const endpoint = `/campaigns/${campaignId}/result/dynamic`
	return axios.put(
		endpoint,
		{
			userResultId: userResultId,
			campaignDynamicId: campaignDynamicId,
			result: {
				sendPicture: {
					userResultId: 0,
					imageUrl: '',
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'application/json',
			},
		},
	)
}

const campaignInitiateWebTemplateCampaign = async (campaignId, campaignDynamicId, userResultId) => {
	const endpoint = `/campaigns/${campaignId}/result/dynamic`
	return axios.put(
		endpoint,
		{
			userResultId: userResultId,
			campaignDynamicId: campaignDynamicId,
			result: {
				webTemplate: {
					userResultId: 0,
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'application/json',
			},
		},
	)
}

const campaignSendPicture = async (campaignId, file, userResultId) => {
	var formData = new FormData()
	formData.append('file', file)

	let resp = await axios.post(
		'/campaigns/' + campaignId + '/result/send-picture/upload',
		formData,
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'multipart/form-data',
			},
		},
	)

	let body = {
		userResultId,
		imageUrl: baseImageDomain + resp.data.filename,
	}
	return axios.put('/campaigns/' + campaignId + '/result/send-picture', body, {
		headers: {
			...getCommonHeaders(),
			'Content-Type': 'application/json',
		},
	})
}

const campaignSendPictureSubCampaign = async (
	campaignId,
	campaignDynamicId,
	file,
	userResultId,
) => {
	var formData = new FormData()
	formData.append('file', file)

	let resp = await axios.post(
		`/campaigns/${campaignId}/result/dynamic/${campaignDynamicId}/send-picture/upload`,
		formData,
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'multipart/form-data',
			},
		},
	)

	let body = {
		userResultId: userResultId,
		campaignDynamicId: campaignDynamicId,
		result: {
			sendPicture: {
				userResultId: userResultId,
				imageUrl: baseImageDomain + resp.data.filename,
			},
		},
	}

	return axios.put(`/campaigns/${campaignId}/result/dynamic`, body, {
		headers: {
			...getCommonHeaders(),
			'Content-Type': 'application/json',
		},
	})
}

const campaignWebTemplateResultSubCampaign = (campaignId, data) => {
	return axios.put(
		`/campaigns/${campaignId}/result/dynamic`,
		{
			userResultId: data.userResultId,
			campaignDynamicId: data.campaignDynamicId,
			result: {
				webTemplate: {
					userResultId: data.userResultId,
					result: data.result ?? '',
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignVoteSubmitAnswer = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/vote', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignVoteSubmitAnswerSubCampaign = (campaignId, data) => {
	return axios.put(
		`/campaigns/${campaignId}/result/dynamic`,
		{
			userResultId: data.userResultId,
			campaignDynamicId: data.campaignDynamicId,
			result: {
				vote: {
					userResultId: data.userResultId,
					answers: data.answers ?? [],
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignSubmitWatchVideoResult = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/watch-video', data, {
		headers: {
			...getCommonHeaders(),
			'Content-Type': 'application/json',
		},
	})
}

const campaignSubmitWatchVideoResultSubCampaign = (campaignId, data) => {
	return axios.put(
		`/campaigns/${campaignId}/result/dynamic`,
		{
			userResultId: data.userResultId,
			campaignDynamicId: data.campaignDynamicId,
			result: {
				watchVideo: {
					userResultId: data.userResultId,
					isWatchEnd: data.isWatchEnd,
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
				'Content-Type': 'application/json',
			},
		},
	)
}

const campaignGetAnnouncement = (id) => {
	return axios.get('/campaigns/' + id + '/announcement', {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignGetAnnouncementLuckyDraw = (campaignId, rewardId) => {
	return axios.get('/campaigns/' + campaignId + '/announcement/lucky-draw/' + rewardId, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignStampEvent = (id, { eventType, data }) => {
	return axios.post(
		'/campaigns/' + id + '/stamp-event',
		{
			eventType,
			data,
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignPhotoHuntSelectAnswer = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/photo-hunt', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignPhotoHuntSelectAnswerSubCampaign = (campaignId, data) => {
	const endpoint = `/campaigns/${campaignId}/result/dynamic`

	return axios.put(
		endpoint,
		{
			userResultId: data.userResultId,
			campaignDynamicId: data.campaignDynamicId,
			result: {
				photoHunt: {
					...data,
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignPointCollectionCodeSendCode = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/point-collection-code', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignPointCollectionCodeSendCodeSubCampaign = (campaignId, data) => {
	const endpoint = `/campaigns/${campaignId}/result/dynamic`
	const isInit = data.isInit
	return axios.put(
		endpoint,
		{
			userResultId: data.userResultId,
			campaignDynamicId: data.campaignDynamicId,
			result: {
				pointCollectionCode: {
					...data,
					userResultId: isInit ? 0 : data.userResultId,
				},
			},
		},
		{
			headers: {
				...getCommonHeaders(),
			},
		},
	)
}

const campaignClickAndGetSomething = (id, data) => {
	return axios.post('/campaigns/' + id + '/result/click-and-get-something', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignLuckyDrawSubmitAnswer = (id, data) => {
	return axios.put('/campaigns/' + id + '/result/lucky-draw', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignSurveyTemplate = (id, data) => {
	return axios.post('/campaigns/' + id + '/result/survey-template', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignDynamic = (id, data) => {
	return axios.post('/campaigns/' + id + '/result/dynamic', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

const campaignDynamicEndGame = (id, dynamicID, data) => {
	return axios.post('/campaigns/' + id + '/result/dynamic/' + dynamicID + '/set-end-flow', data, {
		headers: {
			...getCommonHeaders(),
		},
	})
}

export default {
	axios,
	point: {
		history: pointHistory,
	},
	company: {
		get: companyGet,
		getLanding: companyGetLanding,
		getCampaign: companyGetCampaign,
	},
	campaign: {
		stampCardSubmitAnswer: campaignStampCardSubmitAnswer,
		stampCardSubmitPhoto: campaignStampCardSubmitPhoto,
		questionnaireSubmitAnswer: campaignQuestionnaireSubmitAnswer,
		questionnaireSubmitAnswerSubCampaign: campaignQuestionnaireSubmitAnswerSubCampaign,
		questionnaireSubmitPhoto: campaignQuestionnaireSubmitPhoto,
		questionnaireSubmitPhotoSubCampaign: campaignQuestionnaireSubmitPhotoSubCampaign,
		friendsGetFriendsSubmitAnswer: campaignFriendsGetFriendsSubmitAnswer,
		friendsGetFriendsSubmitPhoto: campaignFriendsGetFriendsSubmitPhoto,
		voteSubmitAnswer: campaignVoteSubmitAnswer,
		voteSubmitAnswerSubCampaign: campaignVoteSubmitAnswerSubCampaign,
		initiatePicture: campaignInitiatePicture,
		initiatePictureSubCampaign: campaignInitiatePictureSubCampaign,
		initiateWebTemplateSubCampaign: campaignInitiateWebTemplateCampaign,
		submitWebTemplateSubCampaign: campaignWebTemplateResultSubCampaign,
		sendPicture: campaignSendPicture,
		sendPictureSubCampaign: campaignSendPictureSubCampaign,
		submitVideoResult: campaignSubmitWatchVideoResult,
		submitVideoResultSubCampaign: campaignSubmitWatchVideoResultSubCampaign,
		getAnnouncement: campaignGetAnnouncement,
		getAnnouncementLuckyDraw: campaignGetAnnouncementLuckyDraw,
		stampEvent: campaignStampEvent,
		photoHuntSelectAnswer: campaignPhotoHuntSelectAnswer,
		photoHuntSelectAnswerSubCampaign: campaignPhotoHuntSelectAnswerSubCampaign,
		pointCollectionCodeSendCode: campaignPointCollectionCodeSendCode,
		pointCollectionCodeSendCodeSubCampaign: campaignPointCollectionCodeSendCodeSubCampaign,
		clickAndGetSomething: campaignClickAndGetSomething,
		luckyDrawSubmitAnswer: campaignLuckyDrawSubmitAnswer,
		surveyTemplate: campaignSurveyTemplate,
		dynamic: campaignDynamic,
		dynamicEndGame: campaignDynamicEndGame,
	},
	additional: {
		upload: campaignAdditionalUplaod,
		submit: campaignAdditionalSubmit,
		check: campaignAdditionalCheck,
	},
}
