import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	email,
	min,
	required,
	min_value,
	max_value,
	integer,
	alpha_num,
	numeric,
} from 'vee-validate/dist/rules'
import Vue from 'vue'
import dayjs from 'dayjs'

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

extend('email', email)
extend('integer', integer)
extend('alpha_num', alpha_num)
extend('required', {
	...required,
	message: 'The field is required.',
})
extend('min', {
	...min,
	params: ['length'],
	message: 'The field must be at least {length}.',
})

extend('min_value', {
	...min_value,
	params: ['min'],
	message: 'The field must be {min} or more.',
})

extend('max_value', {
	...max_value,
	params: ['max'],
	message: 'The field must be {max} or less',
})

extend('timeBefore', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value).isBefore(dayjs(target))
	},
	message: 'The field should be before {target}.',
})

extend('timeAfter', {
	params: ['target'],
	validate(value, { target }) {
		return dayjs(value).isAfter(dayjs(target))
	},
	message: 'The field should be after {target}.',
})

extend('equal', {
	params: ['answer'],
	validate(value, { answer }) {
		return value === Number(answer)
	},
	message: 'There must be {answer} answers.',
})
extend('numeric', {
	...numeric,
	message: 'The field is numeric.',
})
