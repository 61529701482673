import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/campaign/:id/',
		name: 'campaign-home',
		component: () => import('@/views/campaign/Home.vue'),
	},
	{
		path: '/campaign/:id/announcement',
		name: 'campaign-announcement',
		component: () => import('@/views/campaign/Announcement.vue'),
	},
	{
		path: '/campaign/:id/activity',
		name: 'campaign-activity',
		component: () => import('@/views/campaign/Activity.vue'),
	},
	{
		path: '/campaign/:id/activity/stamp-card',
		name: 'campaign-activity-stamp-card',
		component: () => import('@/views/campaign/stamp-card/ActivityStampCard.vue'),
	},
	{
		path: '/campaign/:id/activity/survey-template',
		name: 'campaign-activity-survey-template',
		component: () => import('@/views/campaign/survey-template/ActivitySurveyTemplate.vue'),
	},
	{
		path: '/campaign/:id/additional',
		name: 'campaign-additional',
		component: () => import('@/views/campaign/Additional.vue'),
	},
	{
		path: '/campaign-landing/:id',
		name: 'campaign-landing',
		component: () => import('@/views/campaign/Landing.vue'),
	},
	{
		path: '/campaign-landing/:id/banner',
		name: 'campaign-landing-banner',
		component: () => import('@/views/campaign/Banner.vue'),
	},
	{
		path: '/point/history',
		name: 'point/history',
		component: () => import('@/views/campaign/PointHistory.vue'),
	},
	{
		path: '*',
		component: () => import('@/views/404.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
